import React from "react"
import { Link } from "gatsby"
import { useInView } from "react-intersection-observer"

const Animation = ({
  threshold = 0.15,
  triggerOnce = true,
  right = false,
  ...remainingProps
}) => {
  const [ref, inView] = useInView({ threshold, triggerOnce })

  return (
    <div
      ref={ref}
      style={{
        // adjust these as desired
        transition: "opacity 600ms, transform 600ms",
        display: "flex",
        opacity: inView ? 1 : 0,
        transform: `translateX(${inView ? 0 : right ? 100 : -100}px)`,
      }}
      {...remainingProps}
    />
  )
}

const PostCardMobile = props => {
  return (
    <Animation>
      <Link to={props.node.fields.slug} className="post-card-link">
        <h2 className="donation-title">{props.node.frontmatter.title}</h2>
        <img
          src={props.node.frontmatter.thumbnail.childImageSharp.fluid.src}
          alt="Donateur"
        />
        <p>{props.node.frontmatter.description}</p>
        <Link
          to={props.node.fields.slug}
          className="post-card-link event-button"
        >
          En voir plus
        </Link>
      </Link>
    </Animation>
  )
}

export default PostCardMobile
