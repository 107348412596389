import React from "react"
import { Link } from "gatsby"
import { useInView } from "react-intersection-observer"

const Animation = ({
  threshold = 0.15,
  triggerOnce = true,
  right = false,
  ...remainingProps
}) => {
  const [ref, inView] = useInView({ threshold, triggerOnce })

  return (
    <div
      ref={ref}
      style={{
        // adjust these as desired
        transition: "opacity 600ms, transform 600ms",
        display: "flex",
        opacity: inView ? 1 : 0,
        transform: `translateX(${inView ? 0 : right ? 100 : -100}px)`,
      }}
      {...remainingProps}
    />
  )
}

const PostCard = props => {
  return (
    <article className="post-bg">
      <section className="post-card">
        <div className="post-card-content">
          {props.count % 2 ? (
            <Animation>
              <Link to={props.node.fields.slug} className="post-card-title">
                <img
                  src={
                    props.node.frontmatter.thumbnail.childImageSharp.fluid.src
                  }
                  style={{
                    border: "1px solid #131313",
                  }}
                  alt={props.node.frontmatter.title}
                />
              </Link>
              <div className="post-card-description">
                <h3>
                  {props.node.frontmatter.title || props.node.fields.slug}
                </h3>
                <p>
                  {props.node.frontmatter.description || props.node.fields.slug}
                </p>
                <Link
                  to={props.node.fields.slug}
                  className="post-card-link event-button"
                >
                  En savoir plus
                </Link>
              </div>
            </Animation>
          ) : (
            <Animation right={true}>
              <div className="post-card-description">
                <h3>
                  {props.node.frontmatter.title || props.node.fields.slug}
                </h3>
                <p>
                  {props.node.frontmatter.description || props.node.fields.slug}
                </p>
                <Link
                  to={props.node.fields.slug}
                  className="post-card-link event-button"
                >
                  En savoir plus
                </Link>
              </div>
              <Link to={props.node.fields.slug} className="post-card-title">
                <img
                  src={
                    props.node.frontmatter.thumbnail.childImageSharp.fluid.src
                  }
                  style={{
                    border: "1px solid #131313",
                  }}
                  alt={props.node.frontmatter.title}
                />
              </Link>
            </Animation>
          )}
        </div>
      </section>
    </article>
  )
}

export default PostCard
