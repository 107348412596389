import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import { useInView } from "react-intersection-observer"
import { isMobile } from "react-device-detect"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/postCard"
import PostCardMobile from "../components/postCardMobile"
import Event from "../components/event"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const Animation = ({
  threshold = 0.15,
  triggerOnce = false,
  right = false,
  ...remainingProps
}) => {
  const [ref, inView] = useInView({ threshold, triggerOnce })

  return (
    <div
      ref={ref}
      style={{
        // adjust these as desired
        transition: "opacity 600ms, transform 600ms",
        opacity: inView ? 1 : 0,
        transform: `translateX(${inView ? 0 : 100}px)`,
      }}
      {...remainingProps}
    />
  )
}

const Animation2 = ({
  threshold = 0.15,
  triggerOnce = false,
  right = false,
  ...remainingProps
}) => {
  const [ref, inView] = useInView({ threshold, triggerOnce })

  return (
    <div
      ref={ref}
      style={{
        // adjust these as desired
        transition: "opacity 600ms, transform 600ms",
        opacity: inView ? 1 : 0,
        transform: `rotate(${inView ? 20 : 0}deg)`,
      }}
      {...remainingProps}
    />
  )
}

const Animation3 = ({
  threshold = 0.15,
  triggerOnce = false,
  right = false,
  ...remainingProps
}) => {
  const [ref, inView] = useInView({ threshold, triggerOnce })

  return (
    <div
      ref={ref}
      style={{
        // adjust these as desired
        transition: "opacity 600ms, transform 600ms",
        display: "flex",
        opacity: inView ? 1 : 0,
        transform: `rotate(${inView ? -6.6 : 0}deg)`,
      }}
      {...remainingProps}
    />
  )
}

const getPostCard = (node, postCounter) => {
  if (isMobile) {
    console.log("dddd")
    return (
      <PostCardMobile
        key={node.fields.slug}
        count={postCounter}
        node={node}
        postClass={`post`}
      />
    )
  } else {
    return (
      <PostCard
        key={node.fields.slug}
        count={postCounter}
        node={node}
        postClass={`post`}
      />
    )
  }
}

const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const description = data.site.siteMetadata.description
  const posts = data.allMarkdownRemark.edges
  let postCounter = 0

  return (
    <Layout
      title={siteTitle}
      logo={data.logo.childImageSharp.fluid}
      facebook={data.facebook.childImageSharp.fluid}
      instagram={data.instagram.childImageSharp.fluid}
    >
      <SEO
        title="All posts"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <div className="page-container">
        <section className="section-introduction">
          <h1>{siteTitle}</h1>
          <h4>OHE</h4>
          <div className="section-introduction-bottom">{description}</div>
        </section>

        <div className="section-banner">
          <ul>
            <Animation2>
              <li>
                <img src={data.donation.childImageSharp.fluid.src} />
              </li>
            </Animation2>
            {!isMobile && (
              <Animation3>
                <li>
                  <img src={data.helloasso.childImageSharp.fluid.src} />
                </li>
              </Animation3>
            )}
          </ul>
        </div>
      </div>

      <Event />

      {/* <Bio /> */}
      <div className="post-feed">
        {posts.map(({ node }) => {
          postCounter++
          return getPostCard(node, postCounter)
        })}
        <Animation>
          <Link to={`/donation`} className="post-card-link">
            <h2 className="donation-title">Devenir donateur</h2>
            <div className="donate-card">
              <img
                src={data.donation.childImageSharp.fluid.src}
                alt="Donateur"
              />
              <p>Faire un don</p>
            </div>
          </Link>
        </Animation>
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    donation: file(relativePath: { eq: "donateur.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    helloasso: file(relativePath: { eq: "helloasso.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    instagram: file(relativePath: { eq: "instagram.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    facebook: file(relativePath: { eq: "facebook.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___ord], order: ASC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)
